import React from "react";
import { BaseNode } from "./BaseNode";
import {
  ClipboardDocumentCheckIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";

export const StatusUpdateNode = ({ data, isConnectable }) => {
  return (
    <BaseNode
      data={data}
      isConnectable={isConnectable}
      type="statusUpdate"
      deletable={true}
      id={data.id}
      disableInputHandle={false}
      disableOutputHandle={false}
    >
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <select
                className="text-sm font-medium text-gray-900 bg-transparent border-0 border-b border-gray-300 focus:border-indigo-500 focus:ring-0 p-0"
                value={data.status}
                onChange={(e) => data.onChange?.("status", e.target.value)}
              >
                <option value="in_transit">In Transit</option>
                <option value="arrived">Arrived</option>
                <option value="delayed">Delayed</option>
                <option value="completed">Completed</option>
                <option value="verified">Verified</option>
                <option value="failed">Failed</option>
              </select>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Status Update
              </span>
            </div>

            <div className="mt-3">
              <textarea
                placeholder="Status update notes"
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.notes || ""}
                onChange={(e) => data.onChange?.("notes", e.target.value)}
                rows={2}
              />
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            <ClipboardDocumentCheckIcon className="size-5 text-purple-500" />
          </div>
        </div>

        <div className="px-6 py-4">
          <label className="flex items-center gap-x-3">
            <ClockIcon className="size-5 text-gray-400" />
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={data.requiresApproval}
                onChange={(e) =>
                  data.onChange?.("requiresApproval", e.target.checked)
                }
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span className="ml-2 text-sm text-gray-500">
                Requires Approval
              </span>
            </div>
          </label>
        </div>
      </div>
    </BaseNode>
  );
};
