// @ts-nocheck
import React, { useEffect, useRef } from "react";
import shipmentDashboard from "../../../public/static/screenshots/shipment-dashboard.png";
import { useSpring, animated } from "@react-spring/web";

const Hero = () => {
  // Add ref to track the element
  const heroRef = useRef(null);

  // Initialize a spring animation with useSpring hook
  const [springs, api] = useSpring(() => ({
    scale: 0.7,
    config: {
      tension: 280,
      friction: 60,
    },
  }));

  useEffect(() => {
    const handleScroll = () => {
      // Skip if ref isn't attached
      if (!heroRef.current) return;

      // Get the element's position and dimensions
      const element = heroRef.current;
      const rect = element.getBoundingClientRect();

      // Get viewport height
      const windowHeight = window.innerHeight;

      // Calculate how far the element is from the viewport top
      const elementTop = rect.top;
      const elementHeight = rect.height;

      // Calculate progress based on element position
      // Progress will be 0 when element enters viewport and 1 when it exits
      const progress = Math.min(
        Math.max(
          1 - (elementTop + elementHeight) / (windowHeight + elementHeight),
          0
        ),
        1
      );

      // Rest of the scale calculation remains the same
      const scale =
        progress < 0.7
          ? 0.6 + (progress / 0.7) * 0.4 // Scale up from 0.6 to 1.0 until 70%
          : progress > 0.95
          ? 1.0 - ((progress - 0.95) / 0.05) * 0.4 // Scale down from 1.0 to 0.6 in last 5%
          : 1.0; // Hold at 1.0 between 70% and 95%

      api.start({ scale });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [api]);

  return (
    <div ref={heroRef} className="bg-gray-900">
      <div className="relative isolate mt-24">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="py-24 ">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-7xl text-center">
              <h1 className="text-4xl sm:text-6xl md:text-8xl lg:text-9xl font-semibold tracking-tight text-white">
                Prevent Cargo Theft and Increase Your Profits
              </h1>
              <p className="mt-16 text-pretty text-2xl font-medium text-gray-400 sm:text-md">
                Real-time driver identity verification with cargo photo evidence
                tracking. Protect your cargo and increase your profits today.
              </p>

              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://calendly.com/meet-indemni/30min?background_color=000000&text_color=FFFFFF&primary_color=119DA4"
                  className="rounded-md bg-[#119DA5] px-12 py-6 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </a>
              </div>
            </div>
            <animated.img
              alt="App screenshot"
              src={shipmentDashboard}
              width={2432}
              height={1442}
              className="mt-36 rounded-md bg-white/5 shadow-[0_0_200px_rgba(20,53,96,0.2),_0_0_150px_rgba(17,157,165,0.15),_0_0_100px_rgba(17,157,165,0.1)] ring-1 ring-white/10 "
              style={{
                transform: springs.scale.to((s) => `scale(${s})`),
                transformOrigin: "center center",
              }}
            />
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
