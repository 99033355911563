// @ts-nocheck
import React from "react";
import { BaseNode } from "./BaseNode";
import { ClockIcon, PlayIcon, BoltIcon } from "@heroicons/react/20/solid";

export const StartNode = ({ data, isConnectable }) => {
  const getTriggerIcon = () => {
    switch (data.triggerType) {
      case "scheduled":
        return <ClockIcon className="size-5 text-blue-500" />;
      case "event":
        return <BoltIcon className="size-5 text-yellow-500" />;
      default:
        return <PlayIcon className="size-5 text-green-500" />;
    }
  };

  return (
    <BaseNode
      data={data}
      isConnectable={isConnectable}
      type="startNode"
      deletable={false}
      id={data.id}
      disableInputHandle={true}
      disableOutputHandle={false}
    >
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <label
                htmlFor="workflow-name"
                className="block text-sm font-medium text-gray-700"
              >
                Workflow Name
              </label>
              <input
                id="workflow-name"
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={data.workflowName || ""}
                onChange={(e) =>
                  data.onChange?.("workflowName", e.target.value)
                }
              />
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Workflow
              </span>
            </div>
            <div className="mt-3">
              <select
                className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.triggerType || "manual"}
                onChange={(e) => data.onChange?.("triggerType", e.target.value)}
              >
                <option value="manual">Manual Start</option>
                <option value="scheduled">Scheduled Start</option>
                <option value="event">Event Triggered</option>
              </select>
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            {getTriggerIcon()}
          </div>
        </div>

        {data.triggerType === "scheduled" && (
          <div className="px-6 py-4">
            <div className="flex items-center gap-x-3">
              <ClockIcon className="size-5 text-gray-400" />
              <input
                type="datetime-local"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.scheduledTime || ""}
                onChange={(e) =>
                  data.onChange?.("scheduledTime", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>
    </BaseNode>
  );
};
