// @ts-nocheck
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useVerification,
  useVerificationMutations,
  useCheckStatus,
} from "../../hooks/verifications";
import { useShipment, useShipmentMutations } from "../../hooks/shipments";
import FlowComponent from "../VerificationFlow/FlowComponent";
import PhotoCapture from "../Components/PhotoCapture";
import OCRResultDisplay from "../VerificationFlow/OCRResultDisplay";
import SuccessPage from "../Components/SuccessPage";
import FailurePage from "../Components/FailurePage";
import OCRService from "../../api/OCRService";
import { performVerificationOCR } from "../../api/VerificationService";
import LoadingSpinner from "../Components/LoadingSpinner"; // Create this component
import createPersonaClient from "../../lib/persona";
import { QRCodeSVG } from "qrcode.react";

const IdentityVerification = () => {
  const { shipmentId } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [capturedFile, setCapturedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const [isOCRLoading, setIsOCRLoading] = useState(false);
  console.log("triggering verification");

  const steps = [
    "Identity Verification",
    "Capture Photo",
    "OCR Processing",
    "Complete",
  ];

  // Use the existing hooks
  const { data: shipment, isLoading: isShipmentLoading } =
    useShipment(shipmentId);
  const {
    data: verification,
    isLoading: isVerificationLoading,
    refetch: refetchVerification,
  } = useVerification(shipmentId);
  const { updateVerification, processOCR, checkStatus } =
    useVerificationMutations();
  const { updateShipmentStatus } = useShipmentMutations();

  const handleNext = () => {
    if (currentStep < steps.length) {
      console.log("Current step:", currentStep);
      setCurrentStep(currentStep + 1);
    } else {
      console.log("Flow completed!");
      handleFlowComplete();
    }
  };

  const generatePinCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const handleFlowComplete = () => {
    let verificationUpdated = false;
    let shipmentUpdated = false;

    updateVerification.mutate(
      { shipmentId, data: { state: "Complete" } },
      {
        onSuccess: () => {
          verificationUpdated = true;
          checkCompletion();
        },
        onError: (error) => {
          console.error("Error updating verification:", error);
          setCompletionMessage(
            "An error occurred while completing the verification."
          );
        },
      }
    );

    updateShipmentStatus.mutate(
      { id: shipmentId, status: "Verified" },
      {
        onSuccess: () => {
          shipmentUpdated = true;
          checkCompletion();
        },
        onError: (error) => {
          console.error("Error updating shipment status:", error);
          setCompletionMessage(
            "An error occurred while updating the shipment status."
          );
        },
      }
    );

    const checkCompletion = async () => {
      if (verificationUpdated && shipmentUpdated) {
        const pinCode = generatePinCode();
        try {
          const result = await updateVerification.mutateAsync({
            shipmentId,
            data: { pin_code: pinCode },
          });
          console.log("Verification updated successfully.", result);
        } catch (error) {
          console.error("Error updating verification:", error);
        }
        setCompletionMessage({
          pin: pinCode,
          verificationId: verification.id,
          shipmentId: shipmentId,
        });
      }
    };
  };

  const handlePersonaVerification = async () => {
    console.log("Triggering Verification 2");
    console.log("Verification 2", verification.custom_reference);

    setIsPersonaLoading(true); // Start loading

    try {
      const client = createPersonaClient(
        verification.custom_reference,
        async () => {
          try {
            await updateVerification.mutateAsync({
              shipmentId,
              data: { persona_verification_complete: true },
            });
            console.log("Verification updated successfully.");
          } catch (error) {
            console.error("Error updating verification:", error);
          } finally {
            setIsPersonaLoading(false); // Stop loading
          }
        }
      );
    } catch (error) {
      console.error("Error creating Persona client:", error);
      setIsPersonaLoading(false); // Stop loading on error
    }
  };

  const handleImageCapture = async (file) => {
    setCapturedFile(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);

      try {
        const signedUrl = await OCRService.uploadImage(file, shipmentId);
        setUploadedImageUrl(signedUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleOCRProcessing = async () => {
    setIsOCRLoading(true); // Start loading
    if (capturedFile) {
      await processOCR.mutateAsync({ shipmentId, file: capturedFile });
    }
    setIsOCRLoading(false); // Stop loading
  };

  const handleCheckStatus = async () => {
    setIsCheckingStatus(true);
    try {
      const result = await checkStatus.mutateAsync({ shipmentId });
      setStatusMessage(result.message);
      if (result.success) {
        refetchVerification();
      }
    } catch (error) {
      setStatusMessage("Error checking status: " + error.message);
    } finally {
      setIsCheckingStatus(false);
    }
  };

  const renderContent = () => {
    if (isShipmentLoading || isVerificationLoading) {
      return <LoadingSpinner />;
    }

    if (isPersonaLoading) {
      return <LoadingSpinner />;
    }

    if (isOCRLoading) {
      return <LoadingSpinner />;
    }

    if (completionMessage) {
      return (
        <div className="w-full rounded-lg border border-green-200 bg-green-50 p-4 mb-24">
          <div className="flex flex-col items-center space-y-4">
            <p className="text-green-700 font-medium">
              All verifications are complete. Your PIN code is{" "}
              {completionMessage.pin}. Please share the pin code and the QR code
              with the warehouse representative.
            </p>

            <div className="bg-white p-4 rounded-lg">
              <QRCodeSVG
                value={JSON.stringify({
                  pin: completionMessage.pin,
                  verificationId: completionMessage.verificationId,
                  shipmentId: completionMessage.shipmentId,
                  timestamp: new Date().toISOString(),
                })}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>

            <p className="text-sm text-gray-600">
              Scan this QR code to verify the shipment details
            </p>
          </div>
        </div>
      );
    }

    if (verification?.isComplete) {
      return verification.personaSuccess && verification.ocrSuccess ? (
        <SuccessPage />
      ) : (
        <FailurePage
          personaFailed={!verification.personaSuccess}
          ocrFailed={!verification.ocrSuccess}
        />
      );
    }

    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 1: Identity Verification
            </h3>
            <button
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-md text-sm font-medium font-['Sharp Sans Display No1'] leading-normal mb-2 active:bg-blue-600 transition-colors duration-150"
              onClick={handleCheckStatus}
              disabled={
                isCheckingStatus || verification?.persona_verification_complete
              }
            >
              {isCheckingStatus
                ? "Checking Status..."
                : verification?.persona_verification_complete
                ? "Verification Complete"
                : "Check Verification Status"}
            </button>
            {statusMessage && (
              <p className="text-sm text-gray-600 mt-2">{statusMessage}</p>
            )}
            <button
              className="w-full px-4 py-2 bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
              onClick={handlePersonaVerification}
              disabled={
                verification?.persona_verification_complete ||
                !verification?.persona_verification_url
              }
            >
              {verification?.personaComplete
                ? "Verification Complete"
                : "Start Persona Verification"}
            </button>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 2: Capture Document Photo
            </h3>
            <PhotoCapture
              label="Capture Document"
              photo={capturedFile}
              setPhoto={handleImageCapture}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
            />
            {capturedFile && (
              <button
                onClick={() => {
                  setCapturedFile(null);
                  setPreviewUrl(null);
                  setUploadedImageUrl(null);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md mt-2 active:bg-red-600 transition-colors duration-150"
              >
                Retry Capture
              </button>
            )}
            <p>Capture the document photo for verification.</p>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 3: Process the document for verification
            </h3>
            {processOCR.isLoading ? (
              <div>
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
                <p className="mt-2 text-sm text-gray-600">
                  Processing image...
                </p>
              </div>
            ) : processOCR.isSuccess ? (
              <OCRResultDisplay
                result={processOCR.data}
                onConfirm={handleNext}
              />
            ) : (
              <div>
                {processOCR.isError && (
                  <p className="text-red-500">{processOCR.error.message}</p>
                )}
                <button
                  className="w-full px-4 py-2 bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
                  onClick={handleOCRProcessing}
                  disabled={!capturedFile}
                >
                  Start OCR Processing
                </button>
              </div>
            )}
          </div>
        );
      case 4:
        return (
          <div className="w-full rounded-lg border border-slate-200 p-4 space-y-4 mb-24">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">
                Persona Verification Status:
              </span>
              <span
                className={`text-sm font-bold ${
                  verification?.persona_verification_complete
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {verification?.persona_verification_complete
                  ? "Verified"
                  : "Not Verified"}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">
                Document Verification Status:
              </span>
              <span
                className={`text-sm font-bold ${
                  verification?.ocr_verification_complete
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {verification?.ocr_verification_complete
                  ? "Verified"
                  : "Not Verified"}
              </span>
            </div>
          </div>
        );
    }
  };

  // Add this new function to handle going back
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <FlowComponent
      title="Identity Verification"
      subtitle="Please complete the following steps to verify your identity and shipping documents."
      steps={steps}
      currentStep={currentStep}
      onSubmit={handleNext}
      onBack={handleBack}
      buttonText={currentStep === steps.length ? "Complete" : "Next"}
      showBackButton={currentStep > 1}
      verification={verification}
    >
      {renderContent()}
    </FlowComponent>
  );
};

export default IdentityVerification;
