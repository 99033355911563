import React from "react";
import { Handle, Position, useReactFlow } from "reactflow";

export const BaseNode = ({
  data,
  isConnectable,
  children,
  type,
  disableOutputHandle,
  disableInputHandle,
  deletable,
  id,
}) => {
  const { setNodes, setEdges } = useReactFlow();

  console.log("BaseNode mounted with props:", {
    id,
    type,
    deletable,
    data,
    nodeId: data?.id,
  });

  const getNodeStyle = () => {
    const baseStyle = "px-4 py-2 rounded-lg shadow-md border";
    switch (type) {
      case "verification":
        return `${baseStyle} bg-blue-50 border-blue-200`;
      case "photoDoc":
        return `${baseStyle} bg-green-50 border-green-200`;
      case "statusUpdate":
        return `${baseStyle} bg-purple-50 border-purple-200`;
      case "location":
        return `${baseStyle} bg-yellow-50 border-yellow-200`;
      case "conditional":
        return `${baseStyle} bg-red-50 border-red-200`;
      case "notification":
        return `${baseStyle} bg-gray-50 border-gray-200`;
      default:
        return `${baseStyle} bg-white border-gray-200`;
    }
  };

  const handleDelete = () => {
    console.log("deleting node", id);
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) =>
      edges.filter((edge) => edge.source !== id && edge.target !== id)
    );
  };

  return (
    <div className={getNodeStyle()}>
      {deletable && (
        <button
          onClick={handleDelete}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-sm hover:bg-red-600"
          aria-label="Delete Node"
        >
          X
        </button>
      )}
      {!disableInputHandle && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
        />
      )}
      <div className="min-w-[200px]">
        <div className="font-medium text-gray-700">{data.label}</div>
        {children}
      </div>
      {!disableOutputHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
};
