import React from "react";
import { BaseNode } from "./BaseNode";
import {
  MapPinIcon,
  TruckIcon,
  BuildingOfficeIcon,
  FlagIcon,
  CheckBadgeIcon,
} from "@heroicons/react/20/solid";

export const LocationNode = ({ data, isConnectable }) => {
  const getLocationIcon = () => {
    switch (data.locationType) {
      case "pickup":
        return <TruckIcon className="size-5 text-green-500" />;
      case "delivery":
        return <FlagIcon className="size-5 text-blue-500" />;
      case "warehouse":
        return <BuildingOfficeIcon className="size-5 text-yellow-500" />;
      case "checkpoint":
        return <MapPinIcon className="size-5 text-purple-500" />;
      default:
        return <MapPinIcon className="size-5 text-gray-500" />;
    }
  };

  return (
    <BaseNode
      data={data}
      isConnectable={isConnectable}
      type="location"
      deletable={true}
      id={data.id}
      disableInputHandle={false}
      disableOutputHandle={false}
    >
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <select
                className="text-sm font-medium text-gray-900 bg-transparent border-0 border-b border-gray-300 focus:border-indigo-500 focus:ring-0 p-0"
                value={data.locationType}
                onChange={(e) =>
                  data.onChange?.("locationType", e.target.value)
                }
              >
                <option value="pickup">Pickup Location</option>
                <option value="delivery">Delivery Location</option>
                <option value="warehouse">Warehouse Stop</option>
                <option value="checkpoint">Checkpoint</option>
              </select>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Location
              </span>
            </div>
            <div className="mt-3">
              <div className="flex items-center gap-x-3">
                <MapPinIcon className="size-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Address"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={data.address || ""}
                  onChange={(e) => data.onChange?.("address", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            {getLocationIcon()}
          </div>
        </div>

        <div className="px-6 py-4 space-y-4">
          <div className="grid grid-cols-2 gap-3">
            <div className="flex items-center gap-x-3">
              <input
                type="text"
                placeholder="Latitude"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.latitude || ""}
                onChange={(e) => data.onChange?.("latitude", e.target.value)}
              />
            </div>
            <div className="flex items-center gap-x-3">
              <input
                type="text"
                placeholder="Longitude"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.longitude || ""}
                onChange={(e) => data.onChange?.("longitude", e.target.value)}
              />
            </div>
          </div>

          <label className="flex items-center gap-x-3 rounded-lg border border-gray-200 p-3 hover:bg-gray-50">
            <input
              type="checkbox"
              checked={data.requireVerification}
              onChange={(e) =>
                data.onChange?.("requireVerification", e.target.checked)
              }
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <div className="flex items-center gap-x-2">
              <CheckBadgeIcon className="size-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-900">
                Require verification at location
              </span>
            </div>
          </label>
        </div>
      </div>
    </BaseNode>
  );
};
