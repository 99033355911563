import React from "react";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  BuildingOfficeIcon,
  TruckIcon,
  UserGroupIcon,
  CubeIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useGetUserSession } from "../../hooks/users";
import { useCargoOwnerList } from "../../hooks/cargoOwner";
import { useCarrierList } from "../../hooks/carriers";
import { useDrivers } from "../../hooks/drivers";
import { useShipments } from "../../hooks/shipments";
import { useGetAllVerifications } from "../../hooks/verifications";

const transactions = [
  {
    id: 1,
    name: "Driver Jose Alluez verified",
    href: "#",
    amount: "$20,000",
    currency: "USD",
    status: "Verified",
    date: "January 6, 2025",
    datetime: "2025-01-06",
  },
  {
    id: 3,
    name: "Shipment #B5678 delivered",
    href: "#",
    amount: "$3,200",
    currency: "USD",
    status: "Completed",
    date: "January 4, 2025",
    datetime: "2025-01-04",
  },
  {
    id: 6,
    name: "Driver Liam Smith Verified",
    href: "#",
    amount: "$100",
    currency: "USD",
    status: "Verified",
    date: "January 1, 2025",
    datetime: "2025-01-01",
  },
  {
    id: 7,
    name: "Shipment #C9101 pending",
    href: "#",
    amount: "$4,750",
    currency: "USD",
    status: "Pending",
    date: "December 31, 2024",
    datetime: "2024-12-31",
  },
  {
    id: 8,
    name: "Payment to Driver Emma Johnson",
    href: "#",
    amount: "$1,800",
    currency: "USD",
    status: "Completed",
    date: "December 30, 2024",
    datetime: "2024-12-30",
  },
];

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardContent() {
  const navigate = useNavigate();

  const { session } = useGetUserSession();
  if (!session) {
    navigate("/login");
  }

  const cargoOwnerData = useCargoOwnerList();
  const carrierData = useCarrierList();
  const driverData = useDrivers();
  const shipmentData = useShipments();
  const verificationData = useGetAllVerifications();

  const cards = [
    {
      name: "Cargo Owners",
      href: "/dashboard/cargo-owners",
      icon: BuildingOfficeIcon,
      amount: cargoOwnerData?.data?.length,
    },
    {
      name: "Carriers",
      href: "/dashboard/carriers",
      icon: TruckIcon,
      amount: carrierData?.data?.length,
    },
    {
      name: "Drivers",
      href: "/dashboard/drivers",
      icon: UserGroupIcon,
      amount: driverData?.data?.drivers?.length,
    },
    {
      name: "Shipments",
      href: "/dashboard/shipments",
      icon: CubeIcon,
      amount: shipmentData?.data?.shipments?.length,
    },
    {
      name: "Verifications",
      href: "#",
      icon: CheckBadgeIcon,
      amount: verificationData?.data?.length,
    },
  ];

  return (
    <main className="flex-1 ">
      {/* Page header */}
      <div>
        <div className="bg-white shadow">
          <div className="px-4 sm:px-6 lg:max-w-8xl lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              {/* Profile Info */}
              <div className="min-w-0 flex-1">
                <div className="flex items-center">
                  <div>
                    <div className="flex items-center">
                      <img
                        className="h-16 w-16 rounded-full sm:hidden"
                        src="https://media.istockphoto.com/id/2050253321/photo/happy-businessman-enjoying-on-the-city-street.jpg?s=2048x2048&w=is&k=20&c=cRX7YWn76AL4tiehhnuqErpZ4ZpGb8k7_ovYjeoqzV8="
                        alt=""
                      />
                      <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                        Good morning, Johnson
                      </h1>
                    </div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                        <BuildingOfficeIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        Worldwide Logistics, Inc.
                      </dd>
                      <dt className="sr-only">Account status</dt>
                      <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        <CheckCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                          aria-hidden="true"
                        />
                        Corporate Account
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              {/* Action buttons */}
              <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Add Shipment
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500"
                  onClick={() => navigate("/dashboard/verify-driver")}
                >
                  Verify Driver
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="bg-white shadow min-h-screen">
          <div className="max-w-8xl px-4 sm:px-6 lg:px-8">
            {/* Overview cards */}
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Overview
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {/* Card */}
              {cards.map((card) => (
                <div
                  key={card.name}
                  className="overflow-hidden rounded-lg bg-slate-200 shadow"
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <card.icon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.name}
                          </dt>
                          <dd>
                            <div className="text-lg font-medium text-gray-900">
                              {card.amount}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-5 py-3">
                    <div className="text-sm">
                      <a
                        href={card.href}
                        className="font-medium text-cyan-700 hover:text-cyan-900"
                      >
                        View all
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Recent activity */}
            <h2 className="mt-8 text-lg font-medium leading-6 text-gray-900">
              Recent activity
            </h2>

            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden">
              <ul className="mt-2 divide-y divide-gray-200">
                {transactions.map((transaction) => (
                  <li key={transaction.id}>
                    <a
                      href={transaction.href}
                      className="block bg-white px-4 py-4 hover:bg-gray-50"
                    >
                      <span className="flex items-center space-x-4">
                        <span className="flex flex-1 space-x-2 truncate">
                          <CheckBadgeIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="flex flex-col truncate text-sm text-gray-500">
                            <span className="truncate">{transaction.name}</span>
                            <span>
                              <span className="font-medium text-gray-900">
                                {transaction.amount}
                              </span>{" "}
                              {transaction.currency}
                            </span>
                            <time dateTime={transaction.datetime}>
                              {transaction.date}
                            </time>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden sm:block">
              <div className="mt-2">
                <div className="min-w-full overflow-hidden align-middle shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th
                          className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Verifications
                        </th>
                        <th
                          className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Cargo Value
                        </th>
                        <th
                          className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:table-cell"
                          scope="col"
                        >
                          Status
                        </th>
                        <th
                          className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="w-full max-w-0 px-6 py-4 text-sm text-gray-900">
                            <div className="flex">
                              <a
                                href={transaction.href}
                                className="group inline-flex space-x-2 truncate text-sm"
                              >
                                <CheckBadgeIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <p className="truncate text-gray-500 group-hover:text-gray-900">
                                  {transaction.name}
                                </p>
                              </a>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-right text-sm text-gray-500">
                            <span className="font-medium text-gray-900">
                              {transaction.amount}
                            </span>{" "}
                            {transaction.currency}
                          </td>
                          <td className="hidden px-6 py-4 text-sm text-gray-500 md:table-cell">
                            <span
                              className={classNames(
                                statusStyles[transaction.status],
                                "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize"
                              )}
                            >
                              {transaction.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-right text-sm text-gray-500">
                            <time dateTime={transaction.datetime}>
                              {transaction.date}
                            </time>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Pagination */}
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to{" "}
                        <span className="font-medium">5</span> of{" "}
                        <span className="font-medium">5</span> results
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                        Previous
                      </button>
                      <button className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                        Next
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
