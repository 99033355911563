// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import Header from "../Portal/Header";
import Filter from "./VerificationsFilter";
import SummaryStats from "./VerificationsStats";
import DataTable from "./VerificationsTable";
import { useGetAllVerifications } from "../../hooks/verifications";

// VerificationDashboard.js
const VerificationDashboard = () => {
  const [filters, setFilters] = useState({
    searchTerm: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: verificationData, isLoading, error } = useGetAllVerifications();

  const getFilteredData = useCallback(() => {
    if (!verificationData) return [];

    return verificationData.filter((verification) => {
      if (!filters.searchTerm) return true;

      return Object.values(verification).some((value) =>
        value
          ?.toString()
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase())
      );
    });
  }, [verificationData, filters]);

  useEffect(() => {
    const filteredData = getFilteredData();
    setTotalPages(Math.ceil(filteredData.length / limit));
  }, [getFilteredData, limit]);

  if (!verificationData)
    return (
      <div className="text-center text-gray-300 mt-20">
        No verifications available
      </div>
    );

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <Header companyName="Verifications" url="/dashboard/add-verification" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <div className="mt-8">
          <DataTable
            data={getFilteredData()}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const VerificationDashboardContent = () => <VerificationDashboard />;

export default VerificationDashboardContent;
