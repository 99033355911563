// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import Header from "../Portal/Header";
import Filter from "./DriversFilter";
import SummaryStats from "./DriverStats";
import DataTable from "./DriverTable";
import { useDrivers } from "../../hooks/drivers";

// FleetDashboard.js
const DriverDashboardContent = () => {
  const [filters, setFilters] = useState({
    searchTerm: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: driversData, isLoading, error } = useDrivers();

  const getFilteredData = useCallback(() => {
    if (!driversData) return [];

    return driversData.drivers.filter((driver) => {
      if (!filters.searchTerm) return true;

      return Object.values(driver).some((value) =>
        value
          ?.toString()
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase())
      );
    });
  }, [driversData, filters]);

  useEffect(() => {
    const filteredData = getFilteredData();
    setTotalPages(Math.ceil(filteredData.length / limit));
  }, [getFilteredData, limit]);

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (driversData.drivers.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No drivers available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  console.log(driversData);

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <Header companyName="Drivers" url="/dashboard/add-driver" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <SummaryStats data={driversData?.drivers || []} />
        <div className="mt-8">
          <DataTable
            data={getFilteredData()}
            currentPage={page}
            totalPages={totalPages} // Use the locally calculated totalPages
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const DriverDashboard = () => <DriverDashboardContent />;

export default DriverDashboard;
