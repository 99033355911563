// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  HomeIcon,
  GlobeAltIcon,
  TruckIcon,
  Square3Stack3DIcon,
  ArchiveBoxIcon,
  CheckCircleIcon,
  ShieldCheckIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { UserCircleIcon } from "@heroicons/react/24/solid";

const DashboardIcon = ({ icon: Icon, text, to }) => (
  <Link
    to={to}
    className="w-full flex items-center gap-2 px-3 py-2 rounded-md hover:bg-white transition-colors duration-200"
  >
    <Icon className="w-5 h-5 text-slate-700" />
    <span className="text-sm font-medium text-slate-800">{text}</span>
  </Link>
);

const getInitials = (firstName, lastName) => {
  const first = firstName?.[0] || "";
  const last = lastName?.[0] || "";
  return (first + last).toUpperCase() || "NA";
};

const Sidebar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      console.log(user);
      setUser(user);
    };
    getUser();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="w-1/8 min-w-[200px] max-w-[300px] h-screen py-6 bg-slate-200 flex flex-col justify-between">
      <div className="px-2 space-y-1">
        <DashboardIcon icon={HomeIcon} text="Home" to="/dashboard" />
        <DashboardIcon
          icon={ShieldCheckIcon} // Or ShieldCheckIcon
          text="Verifications"
          to="/dashboard/verifications"
        />
        <DashboardIcon
          icon={Square3Stack3DIcon}
          text="Shipments"
          to="/dashboard/shipments"
        />
        <DashboardIcon
          icon={TruckIcon}
          text="Drivers"
          to="/dashboard/drivers"
        />
        <DashboardIcon
          icon={GlobeAltIcon}
          text="Carriers"
          to="/dashboard/carriers"
        />
        <DashboardIcon
          icon={ArchiveBoxIcon}
          text="Cargo Owners"
          to="/dashboard/cargo-owners"
        />
        <DashboardIcon
          icon={ArrowPathIcon}
          text="Workflow"
          to="/dashboard/workflows"
        />
      </div>
      <div className="px-2 flex flex-col items-center gap-2.5">
        <div className="group relative cursor-pointer">
          {user?.user_metadata?.avatar_url ? (
            <img
              src={user.user_metadata.avatar_url}
              alt="Profile"
              className="w-12 h-12 rounded-full object-cover border-2 border-slate-300 hover:border-[#119da5] transition-colors duration-200"
            />
          ) : (
            <div className="w-12 h-12 bg-gradient-to-br from-[#119da5] to-[#0d7d83] rounded-full flex items-center justify-center hover:shadow-lg transition-all duration-200">
              <span className="text-slate-50 text-base font-medium">
                {getInitials(
                  user?.user_metadata?.first_name,
                  user?.user_metadata?.last_name
                )}
              </span>
            </div>
          )}
          <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 bg-slate-800 text-white px-3 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            {`${user?.user_metadata?.first_name || ""} ${
              user?.user_metadata?.last_name || ""
            }` ||
              user?.email ||
              "Anonymous User"}
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="w-full px-3 py-2 text-slate-900 text-sm font-medium hover:bg-slate-300 rounded-md transition-colors duration-200"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
