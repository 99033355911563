// @ts-nocheck
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../lib/supabase";
import { CustomerService } from "../api/CustomersService";

export const useCustomers = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["customers", page, limit],
    queryFn: async () => {
      try {
        const result = await CustomerService.getCustomers(page, limit);
        return result;
      } catch (error) {
        // Ensure error is properly structured
        throw new Error(error.message || "Failed to fetch customers");
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export const useCustomerCompanyId = (userId) => {
  return useQuery({
    queryKey: ["customerCompanyId", userId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("customers")
        .select("company_id")
        .eq("user_id", userId)
        .single();

      if (error) throw error;
      return data?.company_id;
    },
    enabled: !!userId,
  });
};

export const useCustomer = (id) => {
  return useQuery({
    queryKey: ["customer", id],
    queryFn: async () => {
      try {
        const result = await CustomerService.getCustomer(id);
        return result;
      } catch (error) {
        throw new Error(error.message || "Failed to fetch customer");
      }
    },
    enabled: !!id,
    retry: false, // Prevent retries
    refetchOnWindowFocus: false, // Prevent auto-refetching
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export const useCustomersByCompany = (companyId, page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["customers", "company", companyId, page, limit],
    queryFn: () =>
      CustomerService.getCustomersByCompany(companyId, page, limit),
    enabled: !!companyId,
  });
};

export const useCustomerByUserId = (userId) => {
  return useQuery({
    queryKey: ["customer", "user", userId],
    queryFn: () => CustomerService.getCustomerByUserId(userId),
    enabled: !!userId,
  });
};

export const useCustomerMutations = () => {
  const queryClient = useQueryClient();

  const createCustomer = useMutation({
    mutationFn: (customerData) => CustomerService.createCustomer(customerData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
  });

  const updateCustomer = useMutation({
    mutationFn: ({ id, customerData }) =>
      CustomerService.updateCustomer(id, customerData),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      queryClient.invalidateQueries({ queryKey: ["customer", id] });
    },
  });

  const deleteCustomer = useMutation({
    mutationFn: (id) => CustomerService.deleteCustomer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
  });

  return {
    createCustomer,
    updateCustomer,
    deleteCustomer,
  };
};
