// @ts-nocheck
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for default marker icons in react-leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapComponent = ({ markers = [], center, zoom = 13 }) => {
  // If no center is provided, use the first marker's position or default to San Francisco
  const mapCenter =
    center ||
    (markers.length > 0
      ? [markers[0].lat, markers[0].lng]
      : [37.7749, -122.4194]);

  // Calculate bounds if there are multiple markers
  const getBounds = () => {
    if (markers.length <= 1) return null;
    return L.latLngBounds(markers.map((m) => [m.lat, m.lng]));
  };

  return (
    <MapContainer
      center={mapCenter}
      zoom={zoom}
      bounds={getBounds()}
      style={{ height: "100%", width: "100%", borderRadius: "0.5rem" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {markers.map((marker, index) => (
        <Marker key={index} position={[marker.lat, marker.lng]}>
          <Popup>
            <div className="text-sm">
              <p className="font-semibold">
                {marker.label || "Photo Location"}
              </p>
              <p>Lat: {marker.lat.toFixed(6)}</p>
              <p>Lng: {marker.lng.toFixed(6)}</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
