// @ts-nocheck
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const TableHeader = () => (
  <thead className="bg-zinc-100 text-xs uppercase text-gray-700">
    <tr>
      {[
        { id: "id", label: "ID", width: "w-16" },
        { id: "name", label: "Name", width: "w-48" },
        { id: "address", label: "Address", width: "w-64" },
        { id: "city", label: "City", width: "w-32" },
        { id: "state", label: "State", width: "w-24" },
        { id: "zip_code", label: "Zip Code", width: "w-24" },
        { id: "country", label: "Country", width: "w-32" },
        { id: "phone_number", label: "Phone Number", width: "w-40" },
        { id: "email", label: "Email", width: "w-64" },
        { id: "website", label: "Website", width: "w-48" },
        { id: "actions", label: "Actions", width: "w-20" },
      ].map(({ id, label, width }) => (
        <th
          key={id}
          className={`px-4 py-2 text-left font-normal text-zinc-600 ${width}`}
        >
          {label}
        </th>
      ))}
    </tr>
  </thead>
);

const TableCell = ({ children, icon: Icon, columnId }) => (
  <td
    className={`px-4 py-2 text-sm ${
      columnId === "phone_number" ? "whitespace-nowrap min-w-[160px]" : ""
    }`}
    data-testid={columnId ? `carrier-${columnId}` : undefined}
  >
    <div className="flex items-center space-x-2">
      {Icon && <Icon size={16} className="text-gray-700" />}
      <span className="text-gray-600">{children}</span>
    </div>
  </td>
);

const ExpandButton = ({ isExpanded, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center space-x-1 text-blue-600 hover:text-blue-800"
  >
    {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
    <span className="sr-only">{isExpanded ? "Hide" : "View"} Details</span>
  </button>
);

const RowDetails = ({ row }) => {
  const navigate = useNavigate();

  const handleVerification = () => {
    navigate(`/dashboard/vet-carrier`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-100"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-50 p-4 text-sm">
        <div className="space-y-4">
          <h4 className="font-semibold text-slate-600">Carrier Details</h4>
          <p className="text-slate-500">
            <span className="font-semibold">Name:</span> {row.name}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Address:</span> {row.address}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">City:</span> {row.city}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">State:</span> {row.state}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Zip Code:</span> {row.zip_code}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Country:</span> {row.country}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Phone Number:</span>{" "}
            {row.phone_number}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Email:</span> {row.email}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Website:</span>{" "}
            {row.website || "N/A"}
          </p>
        </div>
        <div className="space-y-4">
          <h4 className="font-semibold text-slate-600">
            Additional Information
          </h4>
          <p className="text-slate-500">
            <span className="font-semibold">Safety Rating:</span>{" "}
            <span className="font-semibold text-green-500">
              {row.safety_rating || "N/A"}
            </span>
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Safety Score:</span>{" "}
            <span className="font-semibold text-green-500">84</span>
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">DOT Number:</span>{" "}
            {row.dot_number || "N/A"}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Number of Trucks:</span>{" "}
            {row.number_of_trucks || "N/A"}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Number of Drivers:</span>{" "}
            {row.number_of_drivers || "N/A"}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Insurance Provider:</span>{" "}
            {row.insurance_provider || "N/A"}
          </p>
          <p className="text-slate-500">
            <span className="font-semibold">Is Active:</span>{" "}
            {row.is_active ? "Yes" : "No"}
          </p>
        </div>
        <div className="col-span-2">
          <button
            onClick={handleVerification}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors"
          >
            Verify Carrier
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const TableRow = ({ row, index, expandedRow, setExpandedRow }) => {
  const isExpanded = expandedRow === index;

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50 group">
        <TableCell columnId="id">{row.id}</TableCell>
        <TableCell columnId="name">{row.name}</TableCell>
        <TableCell columnId="address">{row.address}</TableCell>
        <TableCell columnId="city">{row.city}</TableCell>
        <TableCell columnId="state">{row.state}</TableCell>
        <TableCell columnId="zip_code">{row.zip_code}</TableCell>
        <TableCell columnId="country">{row.country}</TableCell>
        <TableCell columnId="phone_number">{row.phone_number}</TableCell>
        <TableCell columnId="email">{row.email}</TableCell>
        <TableCell columnId="website">{row.website || "N/A"}</TableCell>
        <td className="px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ExpandButton
            isExpanded={isExpanded}
            onClick={() => setExpandedRow(isExpanded ? null : index)}
          />
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="10">
            <RowDetails row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div className="flex flex-1 justify-between sm:hidden">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        data-testid="carrier-previous-page"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        data-testid="carrier-next-page"
      >
        Next
      </button>
    </div>
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing page{" "}
          <span data-testid="carrier-total-pages" className="font-medium">
            {currentPage}
          </span>{" "}
          of <span className="font-medium">{totalPages}</span>
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <FiChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
);

const DataTable = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  limit,
  filters = {},
}) => {
  const [expandedRow, setExpandedRow] = useState(null);

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <TableHeader />
          <tbody>
            {data.map((row, index) => (
              <TableRow
                key={row._id}
                row={row}
                index={index}
                expandedRow={expandedRow}
                setExpandedRow={setExpandedRow}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default DataTable;
