// @ts-nocheck
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DriverService } from "../api/DriversService";

export const useDrivers = () => {
  return useQuery({
    queryKey: ["drivers"],
    queryFn: () => DriverService.getAllDrivers(),
    keepPreviousData: true,
  });
};

export const useDriver = (id) => {
  return useQuery({
    queryKey: ["driver", id],
    queryFn: () => DriverService.getDriver(id),
    enabled: !!id,
  });
};

export const useDriverMutations = () => {
  const queryClient = useQueryClient();

  const invalidateDrivers = async (id) => {
    await queryClient.invalidateQueries({ queryKey: ["drivers"] });
    if (id) await queryClient.invalidateQueries({ queryKey: ["driver", id] });
  };

  const createDriver = useMutation({
    mutationFn: DriverService.createDriver,
    onSuccess: () => {
      invalidateDrivers();
    },
    onError: (error) => {
      console.error("Error in mutation:", error);
    },
  });

  const updateDriver = useMutation({
    mutationFn: ({ id, driverData }) =>
      DriverService.updateDriver(id, driverData),
    onSuccess: (_, { id }) => invalidateDrivers(id),
    onError: (error) => {
      console.error("Error updating driver:", error);
    },
  });

  const deleteDriver = useMutation({
    mutationFn: DriverService.deleteDriver,
    onSuccess: (_, id) => invalidateDrivers(id),
    onError: (error) => {
      console.error("Error deleting driver:", error);
    },
  });

  return {
    createDriver,
    updateDriver,
    deleteDriver,
  };
};
