// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Filter from "./CarriersFilter";
import DataTable from "./CarriersTable";
import { useCarrierList } from "../../hooks/carriers";
import { Link } from "react-router-dom";

const CarrierDashboard = () => {
  const [filters, setFilters] = useState({
    searchTerm: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const { data: carriersData, isLoading, error } = useCarrierList();

  const getFilteredData = useCallback(() => {
    if (!carriersData) return [];

    const filtered = carriersData.filter((carrier) => {
      if (!filters.searchTerm) return true;

      return Object.values(carrier).some((value) =>
        value
          ?.toString()
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase())
      );
    });

    // Calculate pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;

    return {
      paginatedData: filtered.slice(startIndex, endIndex),
      totalItems: filtered.length,
    };
  }, [carriersData, filters, page, limit]);

  useEffect(() => {
    const { totalItems } = getFilteredData();
    setTotalPages(Math.ceil(totalItems / limit));
    // Reset to page 1 when filter changes
    if (filters.searchTerm) {
      setPage(1);
    }
  }, [filters, getFilteredData, limit]);

  // Memoize the filtered and paginated data
  const filteredData = useMemo(() => getFilteredData(), [getFilteredData]);

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!carriersData || carriersData.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No carriers available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <div className="font-light w-[98%] pl-8 pt-8 flex justify-between items-center">
        <h1 className="text-2xl font-normal text-slate-600">Carriers</h1>
        <div className="flex gap-4">
          <Link
            to="/dashboard/add-carrier"
            className="w-[180px] px-4 py-4 text-center bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
          >
            Add Carrier
          </Link>
          <Link
            to="/dashboard/vet-carrier"
            className="w-[180px] px-4 py-4 text-center bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
          >
            Vet Carrier
          </Link>
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <div className="mt-8">
          <DataTable
            data={filteredData.paginatedData}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const CarrierDashboardContent = () => <CarrierDashboard />;

export default CarrierDashboardContent;
