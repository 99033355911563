// @ts-nocheck
import React, { useState, useEffect } from "react";
import Filter from "./WorkflowFilter";
import DataTable from "./WorkflowTable";
import { WorkflowService } from "../../api/WorkflowService";
import Header from "../Portal/Header";

const WorkflowDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [workflowsData, setWorkflowsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        setIsLoading(true);
        const data = await WorkflowService.getWorkflows();
        setWorkflowsData(data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  console.log("Workflows Data", workflowsData);

  useEffect(() => {
    if (workflowsData) {
      const totalWorkflows = workflowsData.workflows.length;
      setTotalPages(Math.ceil(totalWorkflows / limit));
    }
  }, [workflowsData, limit]); // Recalculate totalPages when workflowsData or limit changes

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!workflowsData || workflowsData.workflows.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No workflows available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <Header companyName="Workflows" url="/dashboard/workflows/add-workflow" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <div className="mt-8">
          <DataTable
            data={workflowsData?.workflows || []}
            currentPage={page}
            totalPages={totalPages} // Use the locally calculated totalPages
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const WorkflowDashboard = () => <WorkflowDashboardContent />;

export default WorkflowDashboard;
