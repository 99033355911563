import React, { useState } from "react";
import { Filter as FilterIcon } from "lucide-react";
import { Search as SearchIcon } from "lucide-react";

const Filter = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    searchTerm: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    onFilterChange({ searchTerm: value });
  };

  return (
    <form className="flex flex-col sm:flex-row justify-start items-center gap-2 w-full pb-4 pt-4">
      <div className="w-full h-11 pl-4 pr-3 flex flex-row py-2 rounded-lg border border-slate-300 text-slate-500 text-base font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
        <SearchIcon className="w-5 h-5 mr-2 text-slate-600" />
        <input
          type="text"
          name="searchTerm"
          placeholder="Search Cargo Owners"
          value={filters.searchTerm}
          onChange={handleInputChange}
          className="w-full"
        />
      </div>
      <button
        type="button"
        className="py-2 px-3 bg-transparent rounded-md flex items-center justify-center hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <FilterIcon className="w-5 h-5 mr-2 text-slate-600" />
      </button>
    </form>
  );
};

export default Filter;
