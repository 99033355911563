import React from "react";
import VetCarrier from "./VetCarrier";

const VetCarrierForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <VetCarrier />
    </div>
  );
};

export default VetCarrierForm;
