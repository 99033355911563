import React from "react";
import { BaseNode } from "./BaseNode";
import {
  ClockIcon,
  MapPinIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
} from "@heroicons/react/20/solid";

export const ConditionalNode = ({ data, isConnectable }) => {
  const getConditionIcon = () => {
    switch (data.conditionType) {
      case "time":
        return <ClockIcon className="size-5 text-blue-500" />;
      case "location":
        return <MapPinIcon className="size-5 text-yellow-500" />;
      case "status":
        return <DocumentCheckIcon className="size-5 text-green-500" />;
      case "verification":
        return <CheckBadgeIcon className="size-5 text-purple-500" />;
      default:
        return <DocumentCheckIcon className="size-5 text-gray-500" />;
    }
  };

  return (
    <BaseNode
      data={data}
      isConnectable={isConnectable}
      type="conditional"
      deletable={true}
      id={data.id}
      disableInputHandle={true}
      disableOutputHandle={true}
    >
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-sm font-medium text-gray-900">Condition</h3>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Decision Point
              </span>
            </div>
            <div className="mt-3">
              <select
                className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.conditionType}
                onChange={(e) =>
                  data.onChange?.("conditionType", e.target.value)
                }
              >
                <option value="time">Time-based</option>
                <option value="status">Status-based</option>
                <option value="location">Location-based</option>
                <option value="verification">Verification-based</option>
              </select>
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            {getConditionIcon()}
          </div>
        </div>

        <div className="px-6 py-4">
          {data.conditionType === "time" && (
            <div className="space-y-3">
              <div className="flex items-center gap-x-3">
                <ClockIcon className="size-5 text-gray-400" />
                <input
                  type="time"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={data.timeValue || ""}
                  onChange={(e) => data.onChange?.("timeValue", e.target.value)}
                />
              </div>
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.timeCondition}
                onChange={(e) =>
                  data.onChange?.("timeCondition", e.target.value)
                }
              >
                <option value="before">Before</option>
                <option value="after">After</option>
              </select>
            </div>
          )}

          {data.conditionType === "status" && (
            <div className="flex items-center gap-x-3">
              <DocumentCheckIcon className="size-5 text-gray-400" />
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.statusValue}
                onChange={(e) => data.onChange?.("statusValue", e.target.value)}
              >
                <option value="verified">Verified</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>
              </select>
            </div>
          )}

          {data.conditionType === "location" && (
            <div className="space-y-3">
              <div className="flex items-center gap-x-3">
                <MapPinIcon className="size-5 text-gray-400" />
                <input
                  type="number"
                  placeholder="Radius (miles)"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={data.radius || ""}
                  onChange={(e) => data.onChange?.("radius", e.target.value)}
                />
              </div>
              <select
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.locationCondition}
                onChange={(e) =>
                  data.onChange?.("locationCondition", e.target.value)
                }
              >
                <option value="within">Within radius</option>
                <option value="outside">Outside radius</option>
              </select>
            </div>
          )}
        </div>
      </div>
    </BaseNode>
  );
};
