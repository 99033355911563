// @ts-nocheck
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FiCalendar,
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
  FiChevronRight,
  FiLoader,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSignedUrl } from "../../hooks/photos";

const TableHeader = () => (
  <thead className="bg-zinc-100 text-xs uppercase text-gray-700">
    <tr>
      {[
        "ID",
        "Status",
        "Load ID",
        "Origin",
        "Destination",
        "Carrier Number",
        "Container Number",
        "Condition",
        "Actions",
      ].map((header) => (
        <th
          key={header}
          className="px-4 py-2 text-left font-normal text-zinc-600"
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const TableCell = ({ children, icon: Icon, columnId }) => (
  <td
    className="px-4 py-2 text-sm"
    data-testid={columnId ? `shipment-${columnId}` : undefined}
  >
    <div className="flex items-center space-x-2">
      {Icon && <Icon size={16} className="text-gray-700" />}
      <span className="text-gray-600">{children}</span>
    </div>
  </td>
);

const ExpandButton = ({ isExpanded, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center space-x-1 text-blue-600 hover:text-blue-800"
  >
    {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
    <span className="sr-only">{isExpanded ? "Hide" : "View"} Details</span>
  </button>
);

const PhotoTile = ({ side, filePath }) => {
  const { signedUrl, error, isLoading } = useSignedUrl(
    filePath?.photo_url || ""
  );

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-full aspect-square rounded-lg overflow-hidden bg-gray-100 border border-gray-200">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <FiLoader className="animate-spin h-6 w-6 text-gray-400" />
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-full text-red-500 text-sm">
            Failed to load image
          </div>
        ) : (
          <img
            src={signedUrl}
            alt={`${side} view`}
            className="w-full h-full object-cover hover:opacity-90 transition-opacity"
          />
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity flex items-end justify-center p-2">
          <button className="text-white text-sm bg-black/50 px-3 py-1 rounded-full mb-2 hover:bg-black/70 transition-colors">
            View Full
          </button>
        </div>
      </div>
      <span className="mt-2 text-sm font-medium text-slate-600 capitalize">
        {side}
      </span>
    </div>
  );
};

const PhotoGallery = ({ photoData }) => {
  if (!photoData?.photos) return null;

  return (
    <div className="mt-4">
      <h4 className="font-semibold text-slate-600 mb-4">Load Photos</h4>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {Object.entries(photoData.photos).map(([side, photo]) => (
          <PhotoTile key={side} side={side} filePath={photo} />
        ))}
      </div>
      {photoData.metadata && (
        <div className="mt-4 flex items-center space-x-4 text-xs text-slate-500">
          <span>
            Last updated:{" "}
            {new Date(photoData.metadata.lastUpdated).toLocaleString()}
          </span>
          <span>•</span>
          <span>Total photos: {photoData.metadata.totalPhotos}</span>
        </div>
      )}
    </div>
  );
};

const RowDetails = ({ row }) => {
  const navigate = useNavigate();

  const handleVerification = () => {
    navigate(`/dashboard/load-photos`);
  };

  const handleViewDetails = () => {
    navigate(`/dashboard/shipment/${row.id}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-100"
    >
      <div className="space-y-6 bg-gray-50 p-4 text-sm">
        {/* Photo Gallery Section */}
        {row.photo && <PhotoGallery photoData={row.photo} />}

        {/* Existing Details Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h4 className="font-semibold text-slate-600">Shipment Details</h4>
            <p className="text-slate-500">
              Created At: {new Date(row.created_at).toLocaleString()}
            </p>
            <p className="text-slate-500">
              Cargo Owner ID: {row.cargo_owner_id}
            </p>
            <p className="text-slate-500">Company ID: {row.company_id}</p>
          </div>
          <div>
            <h4 className="font-semibold text-slate-600">Cargo Information</h4>
            <p className="text-slate-500">Pallet Number: {row.pallet_number}</p>
            <p className="text-slate-500">Tags: {row.tags}</p>
            <p className="text-slate-500">
              Is Damaged: {row.is_damaged ? "Yes" : "No"}
            </p>
          </div>
          <div>
            <h4 className="font-semibold text-slate-600">Notes</h4>
            <p className="text-slate-500">
              {row.notes || "No notes available"}
            </p>
          </div>
          <div>
            <h4 className="font-semibold text-slate-600">Documents</h4>
            <p className="text-slate-500">
              Photo: {row.photo ? "Available" : "Not available"}
            </p>
            <p className="text-slate-500">
              Document Photo:{" "}
              {row.document_photo_url ? "Available" : "Not available"}
            </p>
          </div>
        </div>

        {/* Action Button */}
        <div className="flex justify-end">
          <button
            onClick={handleVerification}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {row.photo ? "Update Load Photos" : "Add Load Photos"}
          </button>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleViewDetails}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            View Details
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const TableRow = ({ row, index, expandedRow, setExpandedRow }) => {
  const isExpanded = expandedRow === index;

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50 group">
        <TableCell columnId="id">{row.id}</TableCell>
        <TableCell columnId="status">{row.status}</TableCell>
        <TableCell columnId="load_id">{row.load_id}</TableCell>
        <TableCell columnId="origin_address">{row.origin_address}</TableCell>
        <TableCell columnId="destination_address">
          {row.destination_address}
        </TableCell>
        <TableCell columnId="carrier_number">{row.carrier_number}</TableCell>
        <TableCell columnId="container_number">
          {row.container_number}
        </TableCell>
        <TableCell columnId="condition">{row.condition}</TableCell>
        <td className="px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ExpandButton
            isExpanded={isExpanded}
            onClick={() => setExpandedRow(isExpanded ? null : index)}
          />
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="9">
            <RowDetails row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div className="flex flex-1 justify-between sm:hidden">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        data-testid="shipment-previous-page"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        data-testid="shipment-next-page"
      >
        Next
      </button>
    </div>
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing page <span className="font-medium">{currentPage}</span> of{" "}
          <span data-testid="shipment-total-pages" className="font-medium">
            {totalPages}
          </span>
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <FiChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center p-8">
    <FiLoader className="animate-spin h-8 w-8 text-blue-600" />
    <span className="sr-only">Loading...</span>
  </div>
);

const DataTable = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  limit,
  isLoading,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <table className="table-auto min-w-full">
          <TableHeader />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="9">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <TableRow
                  key={row._id}
                  row={row}
                  index={index}
                  expandedRow={expandedRow}
                  setExpandedRow={setExpandedRow}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default DataTable;
