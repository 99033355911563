import React from "react";

const generateRealisticDriverNames = () => {
  const names = [
    "Carlos M. Rodriguez",
    "Maria Gonzalez",
    "John Smith",
    "Ana Martinez",
    "James L. Johnson",
    "Isabella Ramirez",
    "Jose Torres",
    "Carmen Rivera",
    "Michael Sanchez",
    "Rosa Chavez",
    "David A. Brown",
    "Lucia Ortiz",
  ];
  return names;
};

const driverNames = generateRealisticDriverNames();

const CalendarDay = ({ day, isCurrentMonth, isSelected }) => (
  <div
    className={`w-full aspect-square flex items-center justify-center rounded-md ${
      isSelected
        ? "bg-[#119da5] text-white"
        : isCurrentMonth
        ? "text-slate-900"
        : "text-slate-400 opacity-50"
    }`}
  >
    <span className="text-sm font-medium">{day}</span>
  </div>
);

const ResponsiveCalendar = () => {
  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const days = [
    ...Array(3)
      .fill(null)
      .map((_, i) => ({ day: 28 + i, currentMonth: false })),
    ...Array(31)
      .fill(null)
      .map((_, i) => ({ day: i + 1, currentMonth: true })),
    { day: 1, currentMonth: false },
  ];

  return (
    <div className="w-1/2 max-w-md bg-slate-50 p-4 flex flex-col h-100%">
      <h2 className="text-slate-800 text-xl font-semibold mb-4">
        Drivers scheduled
      </h2>

      <div className="space-y-2 mb-4 flex-grow">
        {driverNames.slice(0, 8).map((name, index) => (
          <div key={index} className="text-black text-sm ">
            {name}
          </div>
        ))}
      </div>

      <div className="bg-white rounded-md shadow border border-slate-200 p-4">
        <div className="flex justify-between items-center mb-4">
          <button className="p-2 opacity-50 bg-white rounded-md border border-slate-200">
            {/* Left arrow icon */}
          </button>
          <span className="text-slate-900 text-sm font-semibold">
            March 2024
          </span>
          <button className="p-2 opacity-50 bg-white rounded-md border border-slate-200">
            {/* Right arrow icon */}
          </button>
        </div>

        <div className="grid grid-cols-7 gap-1 mb-2">
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className="text-center text-slate-500 text-xs font-medium"
            >
              {day}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-1">
          {days.map(({ day, currentMonth }, index) => (
            <CalendarDay
              key={index}
              day={day}
              isCurrentMonth={currentMonth}
              isSelected={currentMonth && day === 2}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCalendar;
