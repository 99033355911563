// @ts-nocheck
import React, { useState, useEffect } from "react";
import CustomDatePicker from "../../Helper/DatePicker";
import CustomTimePicker from "../../Helper/TimePicker";
import InputField from "../../Components/InputField";
import { createVerification } from "../../../api/VerificationService";
import { useCustomerCompanyId } from "../../../hooks/customers";
import { useGetUserSession } from "../../../hooks/users";
import { useDrivers } from "../../../hooks/drivers";
import { useShipments } from "../../../hooks/shipments";
import { Link } from "react-router-dom";
import Select from "react-select";

const DriverVerificationForm = () => {
  const { session, loading: sessionLoading } = useGetUserSession();
  const { data: companyId, isLoading: companyLoading } = useCustomerCompanyId(
    session?.user?.id
  );
  const {
    data: drivers,
    isLoading: isLoadingDrivers,
    error: driversError,
  } = useDrivers();
  const {
    data: shipments,
    isLoading: isLoadingShipments,
    error: shipmentsError,
  } = useShipments();

  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    driver_id: "",
    shipment_id: "",
    phoneNumber: "",
    selectedDate: new Date(),
    selectedTime: new Date(),
    companyId: "",
  });

  useEffect(() => {
    if (companyId) {
      setFormData((prev) => ({ ...prev, companyId }));
    }
  }, [companyId]);

  if (
    sessionLoading ||
    companyLoading ||
    isLoadingDrivers ||
    isLoadingShipments
  ) {
    return <div>Loading...</div>;
  }

  if (driversError || shipmentsError) {
    return <div>Error loading data. Please try again later.</div>;
  }

  const driverOptions =
    drivers?.drivers.map((driver) => ({
      value: driver.id,
      label: `${driver.first_name} ${driver.last_name}`,
    })) || [];

  const shipmentOptions =
    shipments?.shipments.map((shipment) => ({
      value: shipment.load_id,
      label: `${shipment.load_id} - ${shipment.description}`,
    })) || [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneNumberChange = (e) => {
    const formatted = e.target.value.replace(/[^\d\s()-]/g, "");
    setFormData((prevData) => ({ ...prevData, phoneNumber: formatted }));
  };

  const handleSelectChange = (name, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: option ? option.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);
    if (!agreed) {
      setError("Please agree to the Terms of Use and Privacy Policy.");
      return;
    }

    try {
      const { data, error } = await createVerification({
        phoneNumber: formData.phoneNumber,
        shipment_id: formData.shipment_id,
        driver_id: formData.driver_id,
        selectedDate: formData.selectedDate,
        selectedTime: formData.selectedTime,
        companyId: formData.companyId,
      });

      if (error) {
        throw error;
      }

      setMessage(
        `Verification link sent via SMS to ${formData.phoneNumber}. Please check your phone.`
      );
      setError("");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              24/7 Driver Verification
            </h2>
            <p className="text-slate-500 text-sm">
              Enter driver information and expected delivery time.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              {/* Driver Select */}
              <div>
                <Select
                  htmlFor="driver_id"
                  options={driverOptions}
                  value={driverOptions.find(
                    (option) => option.value === formData.driver_id
                  )}
                  onChange={
                    (option) => handleSelectChange("driver_id", option) // Use 'driver_id' here
                  }
                  placeholder="Select Driver"
                  isSearchable
                  className="w-full text-slate-600"
                />
                <Link
                  to="/dashboard/add-driver"
                  className="text-blue-500 text-sm mt-1 block"
                >
                  Driver not listed? Add a new driver
                </Link>
              </div>
              {/* Shipment Select */}
              <div>
                <Select
                  htmlFor="shipment_id"
                  options={shipmentOptions}
                  value={shipmentOptions.find(
                    (option) => option.value === formData.shipment_id
                  )}
                  onChange={
                    (option) => handleSelectChange("shipment_id", option) // Use 'shipment_id' here
                  }
                  placeholder="Select Shipment"
                  isSearchable
                  className="w-full text-slate-600"
                />
                <Link
                  to="/dashboard/add-shipment"
                  className="text-blue-500 text-sm mt-1 block"
                >
                  Shipment not listed? Add a new shipment
                </Link>
              </div>
              <InputField
                label="Phone Number"
                placeholder="(555) 555-5555"
                htmlFor="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <div className="flex flex-row gap-2">
                <CustomDatePicker
                  label="Date Delivery"
                  selectedDate={formData.selectedDate}
                  onChange={(date) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      selectedDate: date,
                    }))
                  }
                />
                <CustomTimePicker
                  label="Time of Delivery"
                  selectedTime={formData.selectedTime}
                  onChange={(time) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      selectedTime: time,
                    }))
                  }
                />
              </div>
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  className="w-4 h-4 rounded border-gray-300"
                />
                <label htmlFor="terms" className="text-black text-sm">
                  I agree to our Terms of Service and Privacy Policy.
                </label>
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DriverVerificationForm;
