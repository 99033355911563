// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../Portal/Header";
import Filter from "./ShipmentFilter";
import SummaryStats from "./ShipmentStats";
import DataTable from "./ShipmentTable";
import { useShipments } from "../../hooks/shipments";
import { supabase } from "../../lib/supabase"; // Make sure this import exists

export const processPhotos = async (photoData) => {
  if (!photoData?.photos) return null;

  try {
    // Create a new object with signed URLs
    const processedPhotos = {};

    // Process each photo
    await Promise.all(
      Object.entries(photoData.photos).map(async ([side, filePath]) => {
        try {
          // Extract photo_url from the filePath object
          const path =
            typeof filePath === "object" ? filePath.photo_url : filePath;

          if (typeof path !== "string") {
            console.error(`Invalid path type for ${side}:`, path);
            return;
          }

          const { data, error } = await supabase.storage
            .from("loadPhotos")
            .createSignedUrl(path, 3600); // 1 hour expiry

          if (error) {
            console.error(`Error getting signed URL for ${side}:`, error);
            return;
          }

          processedPhotos[side] = data.signedUrl;
        } catch (error) {
          console.error(`Error processing ${side} photo:`, error);
        }
      })
    );

    // Return the processed photo data with signed URLs
    return {
      photos: processedPhotos,
      metadata: photoData.metadata,
    };
  } catch (error) {
    console.error("Error processing photos:", error);
    return null;
  }
};

const ShipmentDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    data: shipmentsData,
    isLoading,
    error,
  } = useShipments(page, limit, filters);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  const totalPages = Math.ceil((shipmentsData?.total || 0) / limit);

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (isLoading)
    return <div className="text-center text-gray-500 mt-20">Loading...</div>;

  if (
    !isLoading &&
    (!shipmentsData || Object.keys(shipmentsData).length === 0)
  ) {
    return (
      <div className="text-center text-gray-500 mt-20">
        No shipments available
      </div>
    );
  }

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <Header companyName="Shipments" url="/dashboard/add-shipment" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={handleFilterChange} />
        </div>
        <SummaryStats data={shipmentsData?.shipments || []} />
        <div className="mt-8">
          <DataTable
            data={shipmentsData?.shipments || []}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const ShipmentDashboard = () => <ShipmentDashboardContent />;

export default ShipmentDashboard;
