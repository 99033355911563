import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({
  label,
  selectedDate,
  onChange,
  htmlFor,
  ariaLabel,
  testId,
}) => {
  return (
    <div className="flex flex-col space-y-1.5 mb-4">
      <label htmlFor={htmlFor} className="text-slate-900 text-sm font-medium">
        {label}
      </label>
      <div className="relative">
        <DatePicker
          id={htmlFor}
          selected={selectedDate}
          onChange={onChange}
          dateFormat="MM/dd/yyyy"
          className="w-full px-3 py-2 bg-white rounded-md border border-slate-300 text-slate-700 text-base"
          placeholderText="Select a date"
          aria-label={ariaLabel}
          data-testid={testId}
        />
      </div>
    </div>
  );
};

export default CustomDatePicker;
