// @ts-nocheck
import { useCallback, useState } from "react";
import {
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from "reactflow";
import React from "react";
import "reactflow/dist/style.css";
import { toast } from "react-hot-toast";
import { initialNodes, nodeTypes } from "./nodes/index";
import { initialEdges, edgeTypes, edgeStyles } from "./edges/index";
import { WorkflowService } from "../../../api/WorkflowService";

const nodeTypeLabels = {
  startNode: "Start Shipment",
  endNode: "Complete Shipment",
  verification: "Issue Verification",
  photoDoc: "Take Photo",
  statusUpdate: "Status Update",
  location: "Location Update",
  conditional: "Logic Node (If/Then)",
  notification: "Send Notification",
};

function WorkflowContent() {
  const { saveWorkflow } = WorkflowService;
  const { project } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: "start",
      type: "startNode",
      position: { x: -125, y: -250 },
      data: {
        label: "Start Shipment",
        workflowName: "",
        triggerType: "manual",
        onChange: (key, value) => {
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === "start") {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    [key]: value,
                  },
                };
              }
              return node;
            })
          );
        },
      },
    },
    {
      id: "end",
      type: "endNode",
      position: { x: -120, y: 400 },
      data: {
        label: "Complete Shipment",
        completionAction: "",
        notifyStakeholders: {},
        onChange: (key, value) => {
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === "end") {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    [key]: value,
                  },
                };
              }
              return node;
            })
          );
        },
      },
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isPublishing, setIsPublishing] = useState(false);

  const onConnect = useCallback(
    (connection) => {
      const edge = {
        ...connection,
        ...edgeStyles.default,
        id: `e${connection.source}-${connection.target}`,
      };
      setEdges((edges) => addEdge(edge, edges));
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");
      if (!type) {
        console.log("No type data found in drop event");
        return;
      }

      const reactFlowBounds = document
        .querySelector(".react-flow")
        .getBoundingClientRect();

      const position = project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const nodeId = `${type}-${nodes.length + 1}`;
      console.log("Creating new node with ID:", nodeId);

      const newNode = {
        id: nodeId,
        type,
        position,
        deletable: true,
        data: {
          label: `${type} node`,
          id: nodeId,
          onChange: (key, value) => {
            setNodes((nds) =>
              nds.map((node) => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      [key]: value,
                    },
                  };
                }
                return node;
              })
            );
          },
        },
      };

      console.log("Full node data:", newNode);
      setNodes((nds) => nds.concat(newNode));
    },
    [nodes, setNodes, project]
  );

  const clearWorkflow = useCallback(() => {
    setNodes((nds) =>
      nds.filter((node) => node.id === "start" || node.id === "end")
    );
    setEdges([]);
  }, [setNodes, setEdges]);

  const handlePublish = useCallback(async () => {
    console.log("Publishing workflow...");
    const promise = new Promise(async (resolve, reject) => {
      try {
        const workflowName = nodes.find((n) => n.id === "start")?.data
          ?.workflowName;
        if (!workflowName) {
          throw new Error("Workflow name is required");
        }

        const workflowData = {
          name: workflowName,
          workflow_data: {
            nodes: nodes.map((node) => ({
              ...node,
              data: { ...node.data, onChange: undefined },
            })),
            edges,
          },
          trigger_type:
            nodes.find((n) => n.id === "start")?.data?.triggerType || "manual",
          is_active: true,
          version: 1,
        };

        await saveWorkflow(workflowData);
        console.log("Workflow published successfully!");
        resolve("Workflow published successfully!");
      } catch (error) {
        console.error("Error publishing workflow:", error);
        reject(error);
      }
    });

    toast.promise(promise, {
      loading: "Publishing workflow...",
      success: (message) => message,
      error: (error) => error.message,
    });
  }, [nodes, edges]);

  return (
    <div className="h-screen w-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes} // Add this
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        defaultViewport={{ x: 700, y: 300, zoom: 1 }}
        snapToGrid={true}
        snapGrid={[15, 15]}
        connectionMode="loose"
      >
        <Background
          className="bg-gradient-to-br from-slate-900 via-slate-850 to-slate-750"
          color="#ffffff"
          style={{ backgroundColor: "transparent" }}
          variant="dots"
          gap={50}
          size={1}
        />

        <Controls />
        <Panel
          position="top-left"
          className="bg-white p-4 rounded-lg shadow-lg text-gray-500"
        >
          <div className="font-medium mb-2">Workflow Nodes</div>
          {Object.keys(nodeTypes)
            .filter((type) => !["startNode", "endNode"].includes(type))
            .map((type) => (
              <div
                key={type}
                className="mb-2 p-2 bg-gray-50 rounded cursor-grab text-gray-500 hover:cursor-grab active:cursor-grabbing"
                onDragStart={(event) => {
                  event.dataTransfer.setData("application/reactflow", type);
                  event.dataTransfer.effectAllowed = "move";
                }}
                draggable="true"
              >
                {nodeTypeLabels[type] || type}
              </div>
            ))}
          <button
            onClick={handlePublish}
            disabled={isPublishing}
            className="w-full mb-2 p-2 bg-green-50 text-green-600 rounded hover:bg-green-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isPublishing ? "Publishing..." : "Publish Workflow"}
          </button>
          <button
            onClick={clearWorkflow}
            className="w-full mt-2 p-2 bg-red-50 text-red-600 rounded hover:bg-red-100"
          >
            Clear Workflow
          </button>
        </Panel>
      </ReactFlow>
    </div>
  );
}

export default function WorkflowBuilder() {
  return (
    <ReactFlowProvider>
      <WorkflowContent />
    </ReactFlowProvider>
  );
}
