import { MarkerType } from "reactflow";

export const initialEdges = [];

export const edgeStyles = {
  default: {
    type: "smoothstep",
    animated: true, // Add animation
    style: {
      strokeWidth: 2, // Make the line thicker
      stroke: "#6366f1", // Indigo color for better visibility
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#6366f1", // Match the stroke color
    },
  },
  success: {
    type: "smoothstep",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#10B981", // Green
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#10B981",
    },
  },
  failure: {
    type: "smoothstep",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#EF4444", // Red
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#EF4444",
    },
  },
};

// Add edge types configuration
export const edgeTypes = {
  default: "smoothstep",
};
