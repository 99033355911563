import React from "react";
import { useLocation, Link } from "react-router-dom";

const Header = ({ companyName, url, text }) => {
  const location = useLocation();

  const getButtonText = () => {
    if (location.pathname === "/dashboard/shipments") {
      return "Add Shipment";
    } else if (location.pathname === "/dashboard/carriers") {
      return "Add Carrier";
    } else if (location.pathname === "/dashboard/cargo-owners") {
      return "Add Cargo Owner";
    } else if (location.pathname === "/dashboard/verifications") {
      return "Add Verification";
    } else if (location.pathname === "/dashboard/drivers") {
      return "Add Driver";
    } else if (location.pathname === "/dashboard/workflows") {
      return "Add Workflow";
    } else if (location.pathname === "/dashboard/vet-carrier") {
      return "Vet Carrier";
    }
    return null;
  };

  const buttonText = getButtonText();

  return (
    <header className="font-light w-[98%] pl-8 pt-8 flex justify-between items-center">
      <h1 className="text-2xl font-normal text-slate-600">{companyName}</h1>
      {buttonText && (
        <Link
          to={url}
          className="w-[20%] px-4 py-4 text-center bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
        >
          {buttonText}
        </Link>
      )}
    </header>
  );
};

export default Header;
